import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Scissors, Filter, X } from 'lucide-react';

const HairSalonGallery = ({
  services = [
    {
      id: 'classic-cut',
      title: 'Braids',
      image: '/G1.jpg',
      category: 'Cuts'
    },
    {
      id: 'top-knot',
      title: 'Top Knot',
      image: '/G2.jpg',
      category: 'Color'
    },
    {
      id: 'color-treatment',
      title: 'Color Treatment',
      image: '/G13.jpg',
      category: 'Color'
    },
    {
      id: 'Eye-lash',
      title: 'Eye Lash',
      image: '/G14.jpg',
      category: 'Grooming'
    },
    {
      id: 'styling',
      title: 'Styling',
      image: '/G15.jpg',
      category: 'Styling'
    },
    
    {
      id: 'Makeup',
      title: 'Make Up',
      image: '/G7.jpg',
      category: 'Care'
    },
    {
      id: 'Braiding',
      title: 'Braiding',
      image: '/G8.jpg',
      category: 'Styling'
    },
    {
      id: 'hair-dye',
      title: 'Hair Dye',
      image: '/G19.jpg',
      category: 'Cuts'
    },
    {
      id: 'nails',
      title: 'Nails',
      image: '/G10.jpg',
      category: 'Styling'
    },
    {
      id: 'makeup',
      title: 'Makeup',
      image: '/G11.jpg',
      category: 'Styling'
    },
    {
      id: 'styling',
      title: 'Styling',
      image: '/G16.jpg',
      category: 'Styling'
    },
    {
      id: 'manicure',
      title: 'Manicure',
      image: '/G12.jpg',
      category: 'Color'
    }
  ],
  isHomePage = false
}) => {
  const [selectedService, setSelectedService] = useState(null);
  const [activeCategory, setActiveCategory] = useState("All");

  // Dynamically set max items based on page type
  const maxItems = isHomePage ? 6 : 12;

  const categories = ["All", ...new Set(services.map(service => service.category))];

  const filteredServices = useMemo(() => {
    const filtered = activeCategory === "All"
      ? services
      : services.filter(service => service.category === activeCategory);

    return filtered.slice(0, maxItems);
  }, [services, activeCategory, maxItems]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 }
    }
  };

  return (
    <section className="bg-gray-50 py-16 md:py-24 px-4 min-h-screen font-roboto">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center mb-6">
          <h2 className="text-4xl md:text-5xl font-bold text-black mb-4">
            Salon <span className="text-yellow-500">Gallery</span>
          </h2>
          <p className="text-gray-700 text-lg lg:text-xl max-w-2xl mx-auto">
            Explore our work and find inspiration for your next visit.
          </p>
        </div>

        {/* Category Filter */}
        <motion.div 
          className="flex flex-wrap justify-center gap-4 mb-6"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
         
        </motion.div>

        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {filteredServices.map(service => (
            <motion.div
              key={service.id}
              variants={itemVariants}
              whileHover="hover"
              className="bg-white rounded-2xl overflow-hidden shadow-md hover:shadow-xl transition-all cursor-pointer "
              onClick={() => setSelectedService(service)}
            >
              <motion.img 
                src={service.image} 
                alt={service.title} 
                className="w-full h-72 object-cover" 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              />
              <div className="p-4">
               <h3 className="text-lg font-bold text-gray-800">{service.title}</h3>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <AnimatePresence>
          {selectedService && (
            <motion.div 
              className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setSelectedService(null)}
            >
              <motion.div 
                className="bg-white rounded-lg overflow-hidden max-w-md w-full relative"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                onClick={e => e.stopPropagation()}
              >
                <motion.img 
                  src={selectedService.image} 
                  alt={selectedService.title} 
                  className="w-full h-full object-cover" 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                />
               
                <motion.button 
                  onClick={() => setSelectedService(null)}
                  whileHover={{ rotate: 90 }}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                >
                  <X size={24} />
                </motion.button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default HairSalonGallery;