import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import ServiceList from "../components/Services"; // Assuming ServiceList is the services listing component
import Footer from "../components/Footer";
import About from "../components/About";
import Banner from "../components/Banner";

const ServicesPage = () => {

  return (
    <div  className="pt-16 bg-gray-100">
      <Header />
      <ServiceList  />
      <Footer />
    </div>
  );
};

export default ServicesPage;
