import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, Flower, Scissors, Calendar, Phone } from 'lucide-react';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Rustic Amber Theme
  const colors = {
    primary: {
      background: 'bg-gray-950', // Dark gray background for header
      text: 'text-yellow-500 font-roboto',  // Yellow text for header
      accent: 'text-amber-600', 
      button: 'bg-amber-400', // Lighter amber button
      buttonText: 'text-black',
      border: 'border-cool-gray-200',
      subtle: 'text-cool-gray-500'
    },
    mobile: {
      background: 'bg-gradient-to-br from-white via-gray-50 to-gray-100',
      text: 'text-cool-gray-700 font-serif', 
      textAccent: 'text-amber-700', 
      titleGradient: 'bg-gradient-to-r from-amber-600 to-cool-gray-800 bg-clip-text text-yellow-500 capitalize'
    }
  };

  // Scroll effect with preventDefault for mobile menu
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
      
      if (isMobileMenuOpen) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
      } else {
        document.body.style.overflow = 'unset';
        document.body.style.position = 'static';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.style.overflow = 'unset';
      document.body.style.position = 'static';
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const navLinks = [
    { to: "/", label: "Home", icon: Flower },
    { to: "/services", label: "Services", icon: Scissors },
    { to: "/gallery", label: "Gallery", icon: Calendar },
    { to: "/contact", label: "Contact", icon: Phone }
  ];

  // Animation Variants
  const menuVariants = {
    hidden: { 
      opacity: 0, 
      y: "-100%",
      transition: { duration: 0.3 }
    },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.3 }
    }
  };

  const linkVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <>
      {/* Fixed header */}
      <motion.header 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`
          fixed top-0 left-0 right-0 
          ${colors.primary.background} 
          ${colors.primary.text} 
          py-4 z-40 
          transition-all duration-300 
          ${isScrolled ? 'bg-opacity-95 backdrop-blur-md shadow-lg' : ''}
        `}
      >
        <div className="container mx-auto flex justify-between items-center px-6">
          {/* Logo with elegant gradient text */}
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="text-2xl font-semibold tracking-wider"
          >
            <Link 
              to="/" 
              className={`
                ${colors.mobile.titleGradient}
                hover:opacity-80 
                transition-opacity 
                flex items-center 
                space-x-2
              `}
            >
               Glowcity
            </Link>
          </motion.div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            {navLinks.map((link, index) => (
              <motion.div
                key={link.to}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link 
                  to={link.to} 
                  className={`
                    ${colors.primary.text} 
                    hover:${colors.primary.accent} 
                    flex items-center 
                    space-x-2 
                    transition-all 
                    duration-300 
                    group 
                    relative 
                    hover:scale-105
                  `}
                >
                  <link.icon 
                    className={`
                      ${colors.primary.subtle} 
                      group-hover:${colors.primary.accent} 
                      transition-colors
                    `} 
                    size={20} 
                  />
                  <span className="relative">
                    {link.label}
                    <span className={`
                      absolute -bottom-1 left-0 w-0 
                      group-hover:w-full h-0.5 
                      ${colors.primary.accent}
                      transition-all duration-300
                    `}></span>
                  </span>
                </Link>
              </motion.div>
            ))}
            
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => window.location.href = 'tel:+251967433078'}
              className={`
                ${colors.primary.button} 
                ${colors.primary.buttonText}
                py-2 px-6 
                rounded-full 
                hover:opacity-90 
                transition-all 
                flex items-center 
                space-x-2 
                shadow-md 
  
                ${colors.primary.border}
              `}
            >
              <Phone className='text-black ' size={20} />
              <span>Call Now</span>
            </motion.button>
          </nav>

          {/* Mobile Menu Toggle */}
          <motion.div 
            className="md:hidden"
            whileTap={{ scale: 0.9 }}
          >
            <button 
              onClick={toggleMobileMenu} 
              className={`
                ${colors.primary.text} 
                focus:outline-none
              `}
            >
              {isMobileMenuOpen ? (
                <X size={28} className={colors.primary.subtle} />
              ) : (
                <Menu size={28} className='{colors.primary.subtle} mt-2'/>
              )}
            </button>
          </motion.div>
        </div>
      </motion.header>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={menuVariants}
            className={`
              fixed inset-0 
              ${colors.mobile.background}
              ${colors.mobile.text}
              z-50 md:hidden 
              flex flex-col
              p-6
              overflow-y-auto
            `}
          >
            <motion.button 
              onClick={toggleMobileMenu}
              className={`
                absolute top-6 right-6 
                ${colors.primary.subtle}
                hover:opacity-80 
                z-60
              `}
              whileTap={{ scale: 0.9 }}
            >
              <X size={32} />
            </motion.button>

            <div className="flex flex-col items-center justify-center h-full space-y-8 font-roboto">
              {navLinks.map((link, index) => (
                <motion.div
                  key={link.to}
                  variants={linkVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  transition={{ delay: index * 0.1 }}
                >
                  <Link 
                    to={link.to} 
                    onClick={toggleMobileMenu}
                    className={`
                      text-2xl 
                      hover:opacity-80 
                      flex items-center 
                      space-x-4 
                      transition-colors
                      hover:scale-105
                    `}
                  >
                    <link.icon size={28} className={colors.primary.subtle} />
                    <span>{link.label}</span>
                  </Link>
                </motion.div>
              ))}
              
              <motion.button
  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
  onClick={() => window.location.href = 'tel:+251967433078'}
  className={`
    ${colors.primary.button}
    ${colors.primary.buttonText}
    py-2 px-6
    rounded-full
    hover:opacity-90
    transition-all
    flex items-center
    space-x-2
    shadow-md
    ${colors.primary.border}
  `}
>
  <Phone className="text-black" size={20} />
  <span>Call Now</span>
</motion.button>

            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;
