import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Services from "../components/Services";
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import GalleryGrid from "../components/GalleryGrid";
import HairSalonGallery from "../components/GalleryGrid";
import ServiceDetails from "../components/ServiceDetails";

const Home = () => {
    
  return (
    <div>
      <Header />
      <Hero />
      <ServiceDetails />
      <Banner />
      <HairSalonGallery isHomePage={true} />
      <Footer />
    </div>
  );
};

export default Home;
