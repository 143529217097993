import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, MapPin, Phone, Send, CheckCircle, AlertTriangle, ArrowRight } from 'lucide-react';

const Contact = () => {
  const [activeField, setActiveField] = useState(null);

  const contactDetails = [
    {
      icon: Mail,
      title: "Email",
      content: "info@glowcity1.com",
      animationDelay: 0.1
    },
    {
      icon: Phone,
      title: "Phone",
      content: "+251-9 67 43 30 78",
      animationDelay: 0.2
    },
    {
      icon: MapPin,
      title: "Address",
      content: "Bole next to MK Restaurant Addis Ababa Ethiopia",
      animationDelay: 0.3
    }
  ];

  return (
    <section id="contact" className="py-16 md:py-24 bg-gray-50 relative overflow-hidden font-roboto">
      <div className="container mx-auto px-4 md:px-8 relative z-10">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6 }}
          className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 md:gap-12 bg-white shadow-2xl rounded-3xl overflow-hidden border border-yellow-200"
        >
          {/* Contact Form */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="p-6 md:p-12 bg-gray-50 relative order-2 md:order-1"
          >
            <div className="absolute top-0 left-0 w-full h-1 bg-yellow-500"></div>

            <h2 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-black text-center flex items-center justify-center gap-3 md:gap-4">
              <span>Contact Us</span>
              <ArrowRight className="text-yellow-500 w-6 h-6 md:w-8 md:h-8" />
            </h2>

            <form
              action="https://formsubmit.co/info@glowcity1.com"
              method="POST"
              className="space-y-5 md:space-y-6"
            >
              {['name', 'email', 'message'].map((field) => (
                <div key={field} className="relative group">
                  <label
                    className={`absolute left-4 transition-all duration-300 ${
                      activeField === field || document.querySelector(`[name='${field}']`)?.value
                        ? '-top-2 text-xs text-yellow-600'
                        : 'top-4 text-sm text-gray-500'
                    } bg-white px-1 z-10`}
                  >
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>

                  {field !== 'message' ? (
                    <input
                      type={field === 'email' ? 'email' : 'text'}
                      name={field}
                      onFocus={() => setActiveField(field)}
                      onBlur={() => setActiveField(null)}
                      required
                      className="w-full px-4 py-3 md:py-4 border-2 border-gray-300 rounded-xl focus:outline-none focus:border-yellow-500 transition-all text-black"
                    />
                  ) : (
                    <textarea
                      name={field}
                      onFocus={() => setActiveField(field)}
                      onBlur={() => setActiveField(null)}
                      required
                      className="w-full px-4 py-3 md:py-4 border-2 border-gray-300 rounded-xl h-32 md:h-40 focus:outline-none focus:border-yellow-500 transition-all text-black resize-none"
                    ></textarea>
                  )}
                </div>
              ))}

              <button
                type="submit"
                className="w-full px-6 py-3 md:py-4 bg-yellow-500 text-black rounded-xl hover:bg-yellow-600 flex items-center justify-center space-x-2 transition-all group"
              >
                <span>Send Message</span>
                <Send size={20} className="ml-2 group-hover:translate-x-1 transition-transform" />
              </button>
            </form>
          </motion.div>

          {/* Contact Info */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="p-6 md:p-12 flex flex-col justify-center order-1 md:order-2 bg-white"
          >
            <h3 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-black text-center md:text-left">
              Contact Information
            </h3>

            <div className="space-y-4 md:space-y-6">
              {contactDetails.map((contact, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{
                    delay: contact.animationDelay,
                    type: 'spring',
                    stiffness: 300
                  }}
                  className="flex items-center space-x-4 md:space-x-6 p-3 md:p-4 bg-gray-50 rounded-xl border-l-4 border-yellow-500 hover:shadow-md transition-all"
                >
                  <div className="bg-yellow-500 text-black p-2 md:p-3 rounded-full">
                    {React.createElement(contact.icon, { size: 24 })}
                  </div>
                  <div>
                    <h4 className="font-semibold text-black text-base md:text-lg">{contact.title}</h4>
                    <p className="text-gray-700 text-sm md:text-base">{contact.content}</p>
                  </div>
                </motion.div>
              ))}
            </div>

            <div className="mt-6 md:mt-8 p-4 md:p-6 bg-yellow-50 rounded-xl text-center border-l-4 border-yellow-500">
              <p className="text-black italic text-base md:text-lg">
                "Your beauty journey begins with a conversation"
              </p>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
