import React, { useState, useEffect, useRef } from 'react';
import { motion, useViewportScroll, useTransform, AnimatePresence } from 'framer-motion';
import { ChevronRight, Gem, Sparkles, Droplet, Leaf, Star, Diamond } from 'lucide-react';

const Hero = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const backgroundRef = useRef(null);
  const { scrollYProgress } = useViewportScroll();

  // Enhanced Color Scheme with More Nuanced Palette
  const colors = {
    background: 'bg-gradient-to-br from-yellow-500 to-black font-roboto', // Rustic gradient
    text: {
      primary: 'text-amber-300',   // Soft amber for primary text
      secondary: 'text-brown-300', // Warm brown for secondary text
      accent: 'text-amber-200'     // Light amber for accents
    },
    button: {
      bg: 'bg-amber-400 hover:bg-amber-400',  // Lighter amber for button background
      text: 'text-black'                      // White text on buttons
    }
  };
  
  
  
  // Advanced Parallax and Scroll Transformations
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);

  // Mouse Tracking and Parallax
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  // Sophisticated Animation Variants with More Dynamic Interactions
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      y: 50,
      rotate: 10
    },
    visible: {
      opacity: 1, 
      y: 0,
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 15
      }
    },
    hover: {
      scale: 1.05,
      rotate: 3,
      transition: { 
        type: "spring",
        stiffness: 300
      }
    }
  };

  // Enhanced Particle Background with More Complex Dynamics
  const ParticleBackground = () => {
    const particles = Array.from({ length: 75 }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 6 + 2,
      delay: Math.random() * 3,
      color: Math.random() > 0.5 ? 'text-amber-200' : 'text-amber-100'
    }));

    return (
      <motion.div 
        className="absolute inset-0 overflow-hidden pointer-events-none"
        style={{ 
          perspective: 1200,
          transform: `
            rotateX(${(mousePosition.y - window.innerHeight / 2) / 30}deg) 
            rotateY(${-(mousePosition.x - window.innerWidth / 2) / 30}deg)
          `
        }}
      >
        {particles.map((particle) => (
          <motion.div
            key={particle.id}
            initial={{ 
              opacity: 0, 
              x: particle.x + '%', 
              y: particle.y + '%' 
            }}
            animate={{ 
              opacity: [0, 0.7, 0],
              scale: [1, 1.8, 1],
              rotate: [0, 360, 0]
            }}
            transition={{
              duration: 6,
              repeat: Infinity,
              delay: particle.delay,
              ease: "easeInOut"
            }}
            className={`absolute rounded-full ${particle.color} opacity-20`}
            style={{
              width: `${particle.size}px`,
              height: `${particle.size}px`
            }}
          />
        ))}
      </motion.div>
    );
  };

  // New Interactive Elements
  const InteractiveIcons = () => (
    <motion.div 
      className="absolute top-10 right-10 flex space-x-4 opacity-70 hover:opacity-100 transition-opacity"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 0.7, x: 0 }}
      whileHover={{ scale: 1.1 }}
    >
      <motion.div 
        whileHover={{ rotate: 360 }}
        transition={{ duration: 0.5 }}
      >
        <Gem className={`${colors.text.accent} cursor-pointer`} size={32} />
      </motion.div>
      <motion.div 
        whileHover={{ rotate: 360 }}
        transition={{ duration: 0.5 }}
      >
        <Sparkles className={`${colors.text.accent} cursor-pointer`} size={32} />
      </motion.div>
      <motion.div 
        whileHover={{ rotate: 360 }}
        transition={{ duration: 0.5 }}
      >
        <Diamond className={`${colors.text.accent} cursor-pointer`} size={32} />
      </motion.div>
    </motion.div>
  );

  return (
    <motion.section 
      className={`relative h-screen flex items-center justify-center overflow-hidden ${colors.background}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* Enhanced Particle Background */}
      <ParticleBackground />

      {/* Interactive Top Right Icons */}
      <InteractiveIcons />

      {/* Background Image with Enhanced Parallax */}
      <motion.div
        ref={backgroundRef}
        className="absolute inset-0 bg-cover bg-current z-0"
        style={{
          backgroundImage: 'url(/G12.jpg)',
          opacity: opacity,
          y: backgroundY,
          filter: 'brightness(0.7) contrast(1.2)'
        }}
      />
      
      {/* Dark Overlay with Sophisticated Blur */}
      <motion.div 
        className="absolute inset-0 bg-black/30 backdrop-blur-sm z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      />
      
      {/* Content with Enhanced Animations */}
      <motion.div 
        className="relative z-20 text-center max-w-2xl px-4 text-white"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          variants={itemVariants}
          whileHover="hover"
          className="flex justify-center mb-6 space-x-4"
        >
         <motion.div 
  variants={itemVariants} 
  whileHover={{ rotate: 45, scale: 1.2 }}
>
  <Droplet className={`${colors.text.accent} animate-pulse`} size={48} />
</motion.div>
<motion.div 
  variants={itemVariants} 
  whileHover={{ rotate: 45, scale: 1.2 }}
>
  <Sparkles className={`${colors.text.accent} animate-pulse delay-200`} size={48} />
</motion.div>

        </motion.div>
        
        <motion.h1 
          variants={itemVariants}
          className={`text-4xl md:text-6xl font-bold mb-6 leading-tight ${colors.text.primary}`}
        >
          Discover Your Natural<span className={colors.text.secondary}> Glow</span>
        </motion.h1>
        
        <motion.p
          variants={itemVariants}
          className={`text-lg text-gray-100 md:text-xl mb-8 ${colors.text.primary} opacity-80`}
        >
          Welcome to GlowCity, where beauty meets care. We offer personalized treatments to help you look and feel your best.
        </motion.p>
        
        <motion.div variants={itemVariants}>
          <motion.a
            href="/services"
            variants={itemVariants}
            whileHover={{ 
              scale: 1.05,
              boxShadow: "0 0 35px rgba(245, 158, 11, 0.6)"
            }}
            whileTap={{ scale: 0.95 }}
            className={`inline-flex items-center ${colors.button.bg} ${colors.button.text} px-10 py-4 rounded-full text-lg font-medium hover:opacity-90 transition-all shadow-2xl`}
          >
             GlowCity Services 
            <ChevronRight className="ml-3" size={24} />
          </motion.a>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default Hero;