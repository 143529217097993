import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, Info, Sparkles } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  { 
    id: 'makeup',
    title: 'Makeup', 
    description: 'Enhance your natural beauty and take your look to the next level.',
    image: '/G7.jpg',
    details: [
      'Personalized makeup consultation',
      'Flawless application',
      'Special occasion or everyday looks',
      'High-quality products'
    ],
    price: '$50 - $80',
    duration: '45-60 mins'
  },
  { 
    id: 'nail-design',
    title: 'Nail Design', 
    description: 'Make a statement with captivating nail designs.',
    image: '/G10.jpg',
    details: [
      'Customized nail designs',
      'Gel and acrylic options',
      'Artistic patterns',
      'Long-lasting finish'
    ],
    price: '$25 - $45',
    duration: '30-45 mins'
  },
  { 
    id: 'hair-braids',
    title: 'Hair Braids', 
    description: 'Get ready to turn heads with stunning hair braids.',
    image: '/G1.jpg',
    details: [
      'Personalized consultation',
      'Variety of braid styles',
      'Comfortable and secure braids',
      'Long-lasting results'
    ],
    price: '$40 - $70',
    duration: '60-90 mins'
  },
  { 
    id: 'pedicure-manicure',
    title: 'Pedicure & Manicure', 
    description: 'Get pampered and polished with luxurious pedicure and manicure services.',
    image: '/G12.jpg',
    details: [
      'Hand and foot massage',
      'Cuticle care',
      'Polish application',
      'Nail art options'
    ],
    price: '$35 - $60',
    duration: '45-60 mins'
  },
  { 
    id: 'hair-cut-dye',
    title: 'Hair Cut & Hair Dye', 
    description: 'Get a fresh new look with our haircut and hair dye services.',
    image: '/G17.jpg',
    details: [
      'Personalized consultation',
      'Precision haircuts',
      'Vibrant and long-lasting hair dye',
      'Professional styling finish'
    ],
    price: '$50 - $90',
    duration: '60-90 mins'
  },
  { 
    id: 'eyelash-extension',
    title: 'Eyelash Extension', 
    description: 'Get ready to flutter your lashes with our Eyelash Extension service.',
    image: '/G14.jpg',
    details: [
      'Custom lash design',
      'Long-lasting results',
      'Natural and voluminous lashes',
      'Professional application'
    ],
    price: '$60 - $100',
    duration: '45-60 mins'
  }
];


const Services = () => {
  const [hoveredService, setHoveredService] = useState(null);

  return (
    <section className="bg-gray-100 py-24 px-4 sm:px-10 font-roboto">
      <div className="max-w-6xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-16">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
          >
            Elevate Your <span className="text-yellow-500">Beauty Experience</span>
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            className="text-xl text-gray-600 max-w-3xl mx-auto"
          >
            Discover our curated selection of premium services designed to transform and refresh.
          </motion.p>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                delay: index * 0.2,
                duration: 0.5,
              }}
              onMouseEnter={() => setHoveredService(index)}
              onMouseLeave={() => setHoveredService(null)}
              className="relative group"
            >
              <div className="bg-white rounded-2xl shadow-lg overflow-hidden transition-transform duration-300 hover:shadow-2xl">
                {/* Service Image */}
                <div className="relative">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="w-full h-64 object-cover transform transition-transform duration-300 group-hover:scale-110"
                  />

                  {/* Hover Details */}
                  <AnimatePresence>
                    {hoveredService === index && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 bg-yellow-600 bg-opacity-95 flex items-center justify-center text-white p-6"
                      >
                        <div className="text-center">
                          <h4 className="text-2xl font-bold mb-4 flex items-center justify-center">
                            <Sparkles className="mr-3 text-yellow-200" size={32} />
                            Service Highlights
                          </h4>
                          <ul className="space-y-2">
                            {service.details.map((detail, i) => (
                              <li
                                key={i}
                                className="flex items-center justify-center text-sm"
                              >
                                <Info className="mr-2 text-yellow-200" size={16} />
                                {detail}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                {/* Service Details */}
                <div className="p-6 pb-4">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-3">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 mb-4 h-16">{service.description}</p>

                  {/* Price and Duration */}
                  

                  {/* Button */}
                  
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
