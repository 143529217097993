import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Flower, Instagram, Facebook, Twitter, Phone, MapPin, Mail } from 'lucide-react';
import { FaInstagram, FaFacebookF, FaTiktok } from 'react-icons/fa'; // Import TikTok icon here

const Footer = () => {
   

  const colors = {
    background: 'bg-gray-950',
    text: 'text-white', // White text for better contrast
    accent: 'text-yellow-500', // Yellow accent color
    button: 'bg-yellow-500 hover:bg-yellow-600 text-gray-900' // Yellow button with black text
  };

  const socialLinks = [
    { icon: FaInstagram, href: "https://www.instagram.com/glow_city1/?hl=en" },
    { icon: FaFacebookF, href: "https://www.facebook.com/glowcity1/" },
    { icon: FaTiktok, href: "https://www.tiktok.com/@glow_city1" }
  ];

  const quickLinks = [
    { to: "/", label: "Home" },
    { to: "/services", label: "Services" },
    { to: "/gallery", label: "Gallery" },
    { to: "/contact", label: "Contact" }
  ];

  const contactInfo = [
    { icon: Phone, text: "+251-9 67 43 30 78", type: "Phone" },
    { icon: MapPin, text: "Bole next to MK Restaurant Addis Ababa Ethiopia", type: "Address" },
    { icon: Mail, text: "info@glowcity1.com", type: "Email" }

  ];

  return (
    <footer className={`${colors.background} py-10`}>
      <div className="container mx-auto px-4 grid md:grid-cols-3 gap-12 font-roboto">
        {/* Logo and Description */}
        <div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="text-2xl font-semibold tracking-wider mb-6"
          >
            <Link to="/" className={`${colors.accent} hover:opacity-80 transition-opacity flex items-center`}>
              <Flower className="mr-2" size={32} />
              Glowcity
            </Link>
          </motion.div>
          <p className={`${colors.text} mb-6 font-light`}>
          Your one-stop destination for vibrant and innovative beauty experiences. Discover the beauty of GlowCity Hair & Nail Bar with every service.
          </p>
          {/* Social Links */}
          <div className="flex space-x-4">
            {socialLinks.map((social, index) => (
              <motion.a
                key={index}
                href={social.href}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                className={`${colors.accent} hover:opacity-75 transition-colors`}
              >
                <social.icon size={24} />
              </motion.a>
            ))}
          </div>
        </div>

        {/* Quick Links */}
        <div>
          <h4 className={`text-2xl ${colors.accent} font-semibold mb-6`}>
            Quick Links
          </h4>
          <div className="space-y-4">
            {quickLinks.map((link, index) => (
              <motion.div
                key={index}
                whileHover={{ x: 10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Link
                  to={link.to}
                  className={`${colors.text} hover:opacity-75 transition-colors flex items-center`}
                >
                  <span className="mr-2">→</span>
                  {link.label}
                </Link>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Contact Information */}
        <div>
          <h4 className={`text-2xl ${colors.accent} font-semibold mb-6`}>
            Contact Us
          </h4>
          <div className="space-y-4">
            {contactInfo.map((contact, index) => (
              <div key={index} className="flex items-center">
                <contact.icon className={`${colors.accent} mr-3`} size={20} />
                <div>
                  <p className={`${colors.text} text-sm opacity-70`}>{contact.type}</p>
                  <p className={colors.text}>{contact.text}</p>
                </div>
              </div>
            ))}
          </div>
          <motion.button
  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
  onClick={() => window.location.href = 'tel:+251967433078'}
  className={`${colors.button} py-2 px-6 rounded-full hover:opacity-90 transition-all mt-6 flex items-center`}
>
  <Phone className='mr-2' />
  Call Us
</motion.button>
        </div>
      </div>

      {/* Copyright */}
      <div className="mt-16 pt-6 text-center border-t border-gray-700 font-roboto">
      <p className={`${colors.text} text-sm`}>
  © {new Date().getFullYear()} <span className={colors.accent}>GlowCity Hair & Nail Bar.</span> Developed By  
  <a href="https://inernett.com" className="text-yellow-500 hover:text-yellow-600">
     <span> INERNETT</span>
  </a>.
</p>

      </div>
    </footer>
  );
};

export default Footer;
