import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ServicesPage from "./pages/Services";
import GalleryPage from "./pages/Gallery";
import ContactPage from "./pages/Contact";
import ServiceDetails from "./pages/ServiceDetail";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <Router>
        <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/services/:serviceId" element={<ServiceDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
