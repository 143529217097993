import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import GalleryGrid from "../components/GalleryGrid"; // Assuming GalleryGrid is your portfolio grid
import Footer from "../components/Footer";
import HairSalonGallery from "../components/GalleryGrid";

const GalleryPage = () => {
  
  return (
    <div  className="pt-14 bg-gray-100">
      <Header />
      <HairSalonGallery isHomePage={false} />
      <Footer />
  </div>
  );
};

export default GalleryPage;
