import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import ContactForm from "../components/Contact";
import Footer from "../components/Footer";

const ContactPage = () => {
  return (
    <div className="pt-10">
      <Header />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactPage;
