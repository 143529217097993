import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ServiceDetails from "../components/ServiceDetails";

const AboutPage = () => {
  return (
    <>
      <Header />
      <ServiceDetails />
      <Footer />
    </>
  );
};

export default AboutPage;
