import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles, Star } from 'lucide-react';

const Banner = () => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-black py-20 md:py-32 px-4 md:px-20 overflow-hidden relative font-roboto"
    >
      {/* Decorative Elements */}
      <motion.div
        initial={{ opacity: 0, rotate: 0 }}
        animate={{
          opacity: [0, 1, 0],
          rotate: [0, 360, 0],
          scale: [0.8, 1, 0.8]
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
        className="absolute top-10 left-10 opacity-20"
      >
        <Sparkles size={100} className="text-yellow-300" />
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0, rotate: 0 }}
        animate={{
          opacity: [0, 1, 0],
          rotate: [0, -360, 0],
          scale: [0.8, 1, 0.8]
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut",
          delay: 2.5
        }}
        className="absolute bottom-10 right-10 opacity-20"
      >
        <Star size={120} className="text-yellow-300" />
      </motion.div>
      
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center text-center md:text-left space-y-6 md:space-y-0 relative z-10">
        <div className="md:w-2/3 space-y-4 w-full">
          <motion.h2
            initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-3xl md:text-5xl font-bold mb-2 flex flex-col md:flex-row items-center justify-center md:justify-start"
          >
            <span className="mb-2 md:mb-0 md:mr-3">Get in Touch</span>
            <span className="text-white flex items-center justify-center md:justify-start">
               Now
              <Sparkles className="ml-2 text-yellow-300 animate-pulse" size={36} />
            </span>
          </motion.h2>
          
          <motion.p
            initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-lg md:text-xl max-w-xl mx-auto md:mx-0 px-4 md:px-0"
          >
           Our experts are here to assist you with all your beauty needs. Contact us now to schedule your appointment.
          </motion.p>
        </div>
        
        <div className="md:w-1/3 w-full flex justify-center mt-6 md:mt-0">
          <motion.a
            href="/contact"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="inline-flex items-center justify-center bg-white text-black py-3 px-8 rounded-full text-lg md:text-xl font-semibold shadow-xl hover:shadow-2xl transition duration-300 transform hover:bg-gray-50 group"
          >
           Contact Glowcity
            <motion.span
              animate={{ x: [0, 5, 0] }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="ml-2"
            >
              →
            </motion.span>
          </motion.a>
        </div>
      </div>
    </motion.section>
  );
};

export default Banner;