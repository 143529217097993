import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const NotFound = () => {
  return (
    <>
      <Header />
      <main className="h-screen flex flex-col justify-center items-center text-center bg-gray-100 px-4">
        <h1 className="text-6xl font-bold text-red-500 mb-4">404</h1>
        <p className="text-lg text-gray-700 mb-6">
          The page you're looking for doesn't exist.
        </p>
        <a
          href="/"
          className="text-blue-600 underline hover:text-blue-800 transition"
        >
          Go back to Home
        </a>
      </main>
      <Footer />
    </>
  );
};

export default NotFound;
